var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"width":"20%","centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"提示"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_vm._v(" 审核结果：通过 "),_c('a-form',{staticClass:"simiot-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 7 },"wrapper-col":{ span: 17 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"客户名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入客户名称' },
              { max: 50, message: '最多50个字符' } ]
          }]),expression:"['name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入客户名称' },\n              { max: 50, message: '最多50个字符' },\n            ]\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"责任销售"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sale_id', {
            rules: [{ required: true, message: '请选择责任销售' }]
          }]),expression:"['sale_id', {\n            rules: [{ required: true, message: '请选择责任销售' }]\n          }]"}],attrs:{"show-search":"","option-filter-prop":"children","filter-option":_vm.filterOption,"loading":_vm.loadingSaleOption}},_vm._l((_vm.salesOptions),function(user){return _c('a-select-option',{key:user.id,attrs:{"value":user.id}},[_vm._v(" "+_vm._s(user.full_name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"签约主体"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'contracting_party_id',
            { rules: [{ required: true, message: '请选择签约主体' }] } ]),expression:"[\n            'contracting_party_id',\n            { rules: [{ required: true, message: '请选择签约主体' }] },\n          ]"}],attrs:{"loading":_vm.loadingContractingPartyOptions,"show-search":"","filter-option":_vm.filterContractingPartyOption,"placeholder":"请选择签约主体"}},_vm._l((_vm.contractingPartyOptions),function(contracting_party){return _c('a-select-option',{key:contracting_party.id,attrs:{"value":contracting_party.id,"disabled":!contracting_party.effective}},[_vm._v(" "+_vm._s(contracting_party.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {
            normalize: this.$lodash.trim
          }]),expression:"['remark', {\n            normalize: this.$lodash.trim\n          }]"}]})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }