import request from '@/utils/request'

// 查询签约主体列表
export function findContractingParties(params) {
  return request({
    url: `/contracting_parties`,
    method: 'get',
    params: params
  })
}

// 查询所有的签约主体
export function findContractingPartyOptions() {
  return request({
    url: `/contracting_parties/options`,
    method: 'get'
  })
}

// 创建签约主体
export function createContractingParty(data) {
  return request({
    url: `/contracting_parties`,
    method: 'post',
    data
  })
}

// 查询签约主体编辑时的form信息
export function findContractingPartyForm(id) {
  return request({
    url: `/contracting_parties/${id}/form`,
    method: 'get'
  })
}

// 更新签约主体
export function updateContractingParty(id, data) {
  return request({
    url: `/contracting_parties/${id}`,
    method: 'put',
    data
  })
}

// 修改签约主体的有效性
export function updateContractingPartyEffective(id, data) {
  return request({
    url: `/contracting_parties/${id}/effective`,
    method: 'patch',
    data
  })
}
