<template>
  <div>
    <a-modal
      v-model="isShow"
      width="20%"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="提示"
      @ok="handleSubmit"
    >
      审核结果：通过
      <a-form
        class="simiot-compact-form"
        :form="form"
        @submit="handleSubmit"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
      >
        <a-form-item label="客户名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入客户名称' },
                { max: 50, message: '最多50个字符' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="责任销售">
          <a-select
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            :loading="loadingSaleOption"
            v-decorator="['sale_id', {
              rules: [{ required: true, message: '请选择责任销售' }]
            }]"
          >
            <a-select-option
              v-for="user in salesOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.full_name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="签约主体">
          <a-select
            :loading="loadingContractingPartyOptions"
            show-search
            :filter-option="filterContractingPartyOption"
            v-decorator="[
              'contracting_party_id',
              { rules: [{ required: true, message: '请选择签约主体' }] },
            ]"
            placeholder="请选择签约主体"
          >
            <a-select-option
              v-for="contracting_party in contractingPartyOptions"
              :key="contracting_party.id"
              :value="contracting_party.id"
              :disabled="!contracting_party.effective"
            >
              {{ contracting_party.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="备注">
          <a-textarea
            v-decorator="['remark', {
              normalize: this.$lodash.trim
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { passRegisteredAgentAccount, findRegisteredAgentForm } from '@/api/registered_agent'
import { findCanTrackRegisteredAccountSaleOptions } from '@/api/user'
import { findContractingPartyOptions } from '@/api/contracting_party'
export default {
  name: 'PassRegisteredAgent',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'passed_registered_agent_account' }),
      submitting: false,
      loadingSaleOption: true,
      salesOptions: [],
      contractingPartyOptions: [],
      loadingContractingPartyOptions: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFormData()
    this.fetchSalesOptions()
    this.fetchContractingPartiesOption()
  },
  methods: {
    fetchFormData() {
      findRegisteredAgentForm(this.id).then((res) => {
        // 如果销售不存在，表单不设置销售值
        if (res.data.sale_id === 0) {
          delete res.data.sale_id
        }
        this.form.setFieldsValue(res.data)
      })
    },

    // 加载责任销售选项
    fetchSalesOptions() {
      this.loadingSaleOption = true
      findCanTrackRegisteredAccountSaleOptions().then((res) => {
        this.salesOptions = res.data
        this.loadingSaleOption = false
      })
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          passRegisteredAgentAccount(this.id, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    },

    filterContractingPartyOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    fetchContractingPartiesOption() {
      this.loadingContractingPartyOptions = true
      findContractingPartyOptions().then((res) => {
        if (res.code === 0) {
          this.contractingPartyOptions = res.data
        }
        this.loadingContractingPartyOptions = false
      })
    }
  }
}
</script>
